import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const featuresContent =[
    {
        img:'line-round-5b',
        icon:'icon-23b',
        title:'Web Developmet',
        desc:"The online presence of businesses is becoming increasingly important. A professional website reflects your brand's digital identity and allows you to reach your target audience. We are here to help you achieve this goal with our web design solutions tailored to your business.",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-15b',
        title:'App Developmet',
        desc:"In parallel with the rapid increase in the use of mobile devices, the use of mobile applications is spreading very rapidly. With mobile applications, especially cell phones have become a part of our lives in a way that makes our daily lives easier.",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '100',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-20b',
        title:'UI/UX Design',
        desc:"UI/UX design is the process of optimizing the interaction between a product or service and the user. This process aims to make a product both visually appealing and easy to use.",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '150',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-18b',
        title:'Custom Developmet',
        desc:"Corporate companies need unique software to increase their productivity. Software solutions differ due to the habits and goals of companies. Developing the most compatible software is a factor that directly affects company efficiency and performance.",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '200',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-22b',
        title:'E-Commerce',
        desc:"E-commerce is a concept that covers all commercial activities over the internet. It refers to the buying and selling of products or services over the internet, without a physical store. This allows consumers to easily buy anything they want through their computers or smartphones.",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '250',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-19b',
        title:'Dijital Marketing',
        desc:"Search Engine Optimization (SEO) is the process that increases a website's ranking in search engine results and, as a result, gets more people to visit the site. Websites that rank higher in search engine rankings potentially receive more visitors",
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '250',
        dataDelayDown: '100',
    },
]

const FeatureFour = () => {
    return (
        <Fragment>
            <div className="row gx-4 gx-xxl-5">
                {featuresContent.map((val,i)=>(
                    <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                    <div className="feature-style-three mb-45">
                        <img
                            className="shape-over"
                            data-aos={val.dataAosDown}
                            data-aos-delay={val.dataDelayDown}
                            src={`assets/img/shape/${val.img}.svg`}
                            alt="shape"/>
                        <img className='icon' src={`assets/img/icon/${val.icon}.svg`} alt="icon"/>
                        <h2>
                        {/* to="/services-details" */}
                            <Link className="sect-title-two" to='services'>{val.title}</Link>
                        </h2>
                        <p>{val.desc}</p>
                    </div>
                </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FeatureFour