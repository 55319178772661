import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'



const SideBar = (props) => {

    const {onClick, value} = props;
    
    return (
        <Fragment>

            {/* slide-bar start */}
            <aside className={value?"slide-bar show":"slide-bar"}>
                <div className="close-mobile-menu">
                    <a href="#" onClick={onClick}>
                        <i className="fas fa-times"/>
                    </a>
                </div>
                {/* offset-sidebar start */}
                <div className="offset-sidebar">
                    <div className="offset-widget offset-logo mb-30">
                        <Link to="/">
                            <img src="assets/img/logo/footer-logo.PNG" alt="logo"/>
                        </Link>
                    </div>
                    <div className="offset-widget mb-40">
                        <div className="info-widget">
                            <h4 className="offset-title mb-20">About Us</h4>
                            <p className="mb-30">
                            Cruxes is a software company dedicated to developing solutions that go 
                            beyond the surface.We believe that true progress lies in tackling the 
                            fundamental challenges (cruxes) that hinder productivity and success
                            </p>
                        </div>
                    </div>
                    <div className="offset-widget mb-30 pr-10">
                        <div className="info-widget info-widget2">
                            <h4 className="offset-title mb-20">Contact Info</h4>
                            <p>
                                <i className=" bi bi-geo-alt"/>
                                Bursa - Türkiye </p>
                               <p>
                               <p > 
                                <i className="fal fa-phone"/>
                                <a href="tel:{[+90553565744]}">  +90 553 565 74 44</a>
                            </p>
                                <i className=" bi bi-geo-alt"/>
                                Hannover Germany
                                </p>
                          
                            <p> 
                                <i className="fal fa-phone"/>
                                <a href="https://wa.me/+4915252387362"> +49 152 52387362</a>
                            </p>
                            <p>
                                <i  className="fal fa-envelope-open"/>
                                <a href="mailto:info@cruxestack.com">info@cruxestack.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* offset-sidebar end */}
            </aside>
            {/* slide-bar end */}
        </Fragment>
    )
}

export default SideBar