import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'


const socialContent = [
    {
        icon: 'fab fa-linkedin-in',
        url: 'https://www.linkedin.com/company/cruxestack'
    },
    {
        icon: 'fab fa-instagram',
        url: 'https://www.instagram.com/cruxestack'
    },
    {
        icon: 'fab fa-twitter',
        url: 'https://x.com/cruxestack'
    },
    {
        icon: 'fab fa-facebook-f',
        url: 'https://www.facebook.com/profile.php?id=61565189462248'
    },
]

const SocialThree = () => {
    return (
        <Fragment>
            <div className="team-social-three mb-30">
               {socialContent.map((i)=>(
                 <a key={i} href={i.url} target='_blank'>
                    <i className={i.icon}/>
                </a>
               ))}
            </div>
        </Fragment>
    )
}

export default SocialThree