import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'




//componets
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import FooterTwo from '../../../components/footer/FooterTwo'
import { Link } from 'react-router-dom'


const ComingSoon = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Coming Soon || Cruxestack Company</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuOne */}
                <ThemeMenuOne />
                {/* ThemeMenuOne */}

                {/* PageTitle */}
                <PageTitle title="Coming Soon" />
                {/* PageTitle */}

                {/*coming-soon-page start*/}
                <div className="coming-soon-page pt-10 pb-115 pt-lg-60 pb-lg-20">
                    <img className="coming-shape d-none d-xl-inline-block" src="assets/img/shape/line-round-12g.svg" alt="Shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="coming-page-wrapper mb-40">
                                    <h2 className="sect-title text-black">Coming Soon . . .</h2>
                                    <p className="text-black">We are working to understand you better and provide you 
                                        with content that is more relevant to your needs. 
                                        We will soon have new categories, detailed guides and updates on our blog page..</p>
                                    <h3 className="text-black">Our Website Launched In:</h3>
                                    <Link className="theme_btn" to="/">Back To Home</Link>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="under-construction-wrapper mb-40">
                                    <img className="w-100" src="assets/img/ilustration/ilus-04.svg" alt="Ilustration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*coming-soon-page end*/}

                {/*techy-newsletter start*/}
                <NewsletterTwo />
                {/*techy-newsletter end*/}

                {/* FooterTwo start */}
                <FooterTwo />
                {/* FooterTwo end */}

            </div>

        </Fragment>
    )
}

export default ComingSoon