import React, {Fragment,useRef} from 'react'
import emailjs from '@emailjs/browser';



const ContactForm = () => {

 const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7qdw41v', 'template_m4pxq0q', form.current, {
        publicKey: 'OWdbSZYa75SkZvwg1',
      })
      .then(
        () => {
          alert('Thank you for your message, we will reply as soon as possible! ✅');
        },
        (error) => {
          alert('Oops!! Something went wrong. Please try again...❌', error.text);
        },
      );
    };
    return (
        <Fragment>
             <form className="widget-form" ref={form} onSubmit={sendEmail}>
                <div className="row gx-4 gx-xxl-5 px-0">
                    <div className="col-md-6 mb-10">
                       <div className="form-input-box">
                        <label className="fs-16 text-black">Full Name</label>
                            <input 
                            labelText="nameInputLabel"
                            name='user_name'
                            type="text"
                            required="required"
                            />
                       </div>
                    </div>
                    <div className="col-md-6 mb-10">
                        <div className="form-input-box">
                            <label className="fs-16 text-black">E-mail</label>
                            <input
                            labelText="emailInputLabel"
                            name="user_email"
                            type="email"
                            required="required"
                            />
                            
                            <div className="invalid-feedback"></div>
                            </div>
                        </div>
                    <div className="col-md-12 mb-20">
                        <div className="form-input-box">
                            <label className="fs-16 text-black">Message</label>
                            <textarea 
                            labelText="messageInputLabel"
                            type="textarea"
                            name='message'
                            required="required"
                            />
                              <div className="invalid-feedback"></div>
                        </div>
                    </div>
                    <div className="col-12">
                        <button className="theme_btn1"
                         value='Send'
                         type="submit"
                         title="contactFormButton"
                        >Send</button>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export default ContactForm

