import React, {Fragment,useRef} from 'react'
import emailjs from '@emailjs/browser';



export const SubscribeFormOne = () => {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_h4vpmzc', 'template_o79ice2', form.current, {
        publicKey: 'OWdbSZYa75SkZvwg1',
      })
      .then(
        () => {
          alert('Thank you for subscribing to our Newsletter! ✅');
        },
        (error) => {
          alert('Oops!! Something went wrong. Please try again...❌', error.text);
        },
      );
    };
    return (
        <Fragment>
            <form className="sub-form" action="#" ref={form} onSubmit={sendEmail}>
                <input
                 type="email" name="user_email"
                 id="user_email"
                 placeholder="E-Mail"
                 required="required"
                />
                <button
                 value="Send"
                 type="submit"
                 title="subscribeButtonText"
                ><i className="bi bi-arrow-right"/></button>
            </form>
        </Fragment>
    )
}
