import React, {Fragment} from 'react'

const FeaturesContent = [
    {
        icon: 'icon-15b',
        title: 'Hybrid Apps',
        desc: 'Hybrid apps are a very popular solution today, combining both the flexibility of web technologies and the performance of mobile applications.',
        dataAos:'fade-up',
        dataDelay:'',
    },
    {
        icon: 'icon-18b',
        title: 'Custom Software',
        desc: 'Custom software development is the process of creating software applications specifically designed to meet the unique requirements of an individual or organization.',
        dataAos:'fade-up',
        dataDelay:'50',
    },
    {
        icon: 'icon-17b',
        title: 'Support',
        desc: 'Is support on our plan? Feel free to submit a ticket or give us a call during business hours. We will get back to you as soon as possible info@cruxestack.com',
        dataAos:'fade-up',
        dataDelay:'100',
    },
]

const FeatureThree = () => {
    return (
        <Fragment>
            <div className="row gx-4 gx-xxl-5 justify-content-center">
                {FeaturesContent.map((val,i)=>(
                    <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                        <div className="feature-style-three text-center mb-45">
                            <div className="icon"><img src={`assets/img/icon/${val.icon}.svg`} alt="icon"/></div>
                            <h3>
                                <a className="sect-title-two" >{val.title}</a>
                            </h3>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FeatureThree