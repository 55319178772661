import React from 'react'
import {Link} from 'react-router-dom'
import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';



const MobileMenu = (props) => {

  const {onClick, active} = props;



    
  return (
    // <div className={active?"mobile-menu-wrapper show d-lg-none":"mobile-menu-wrapper d-lg-none"}>
    <div className={`mobile-menu-wrapper ${active} d-lg-none`}>
        <div className="mobile-logo mb-5">
            {/* <Link to="/"><img src="assets/img/logo/footer-logo.png" alt="Cruxestack"/></Link> */}
        </div>
        <div className="close-menu" onClick={onClick}>
            <i className="bi bi-x-lg"></i>
        </div>
        <div className="side-mobile-menu">
            <MetisMenu>
               
                    <li className="nav-item">
              <Link  style={{color:'#ffff', fontSize:'18px'}}  className="nav-link" to="/about">About Us</Link>
            </li>
        <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/services">Services</Link>
            </li>
        <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/projects">Projects</Link>
            </li>
        <li className="nav-item">
              <Link  style={{color:'#ffff', fontSize:'18px' }}  className="nav-link" to="/coming-soon">Blog</Link>
            </li>
            <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/contact">Contact</Link>
            </li>
            </MetisMenu>
        </div>

    </div>
  )
}

export default MobileMenu