import React,{Fragment} from 'react'


const socialContent = [
    {
        icon: 'fab fa-linkedin-in',
        url: 'https://www.linkedin.com/company/cruxestack'
    },
    {
        icon: 'fab fa-instagram',
        url: 'https://www.instagram.com/cruxestack'
    },
    {
        icon: 'fab fa-twitter',
        url: 'https://x.com/cruxestack'
    },
    {
        icon: 'fab fa-facebook-f',
        url: 'https://www.facebook.com/profile.php?id=61565189462248'
    },
   
   
  
]

const SocialFive = () => {
    return (
        <Fragment>
            <div className="text-center text-md-end team-social-three social-style-five d-block mb-40">
               {socialContent.map((i)=>(
                 <a key={i} href={i.url}  target='_blank'>
                    <i className={i.icon}/>
                </a>
           ))}
              
            </div>
        </Fragment>
    )
}

export default SocialFive
