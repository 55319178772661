import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'


const ThemeMainMenu = () => {
  return (
    <Fragment>
        <li className="nav-item">
              <Link  style={{color:'#ffff', fontSize:'18px'}}  className="nav-link" to="/about">About Us</Link>
            </li>
        <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/services">Services</Link>
            </li>
        <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/projects">Projects</Link>
            </li>
        <li className="nav-item">
              <Link  style={{color:'#ffff', fontSize:'18px' }}  className="nav-link" to="/coming-soon">Blog</Link>
            </li>
            <li className="nav-item">
              <Link style={{color:'#ffff', fontSize:'18px' }} className="nav-link" to="/contact">Contact</Link>
            </li>
      
    </Fragment>
  )
}

export default ThemeMainMenu