import React, { Fragment, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import Tilt from 'react-parallax-tilt'


//components
import ThemeMenuOne from '../../components/header/ThemeMenuOne'
import FeatureThree from '../../components/feature/FeatureThree'
import BlockStyleSeven from '../../components/block/BlockStyleSeven'
import CounterTwo from '../../components/counter/CounterTwo'
import FeatureFour from '../../components/feature/FeatureFour'
import CaseSliderOne from '../../components/case-study/CaseSliderOne'
import BrandTwo from '../../components/brand/BrandTwo'
import BlockStylFive from '../../components/block/BlockStylFive'
import MarqueSlider from '../../components/marque/MarqueSlider'
import FooterTwo from '../../components/footer/FooterTwo'
//modal video
import ModalVideos from '../../components/modal-video/ModalVideos'
import ThemeBannerThree from '../../components/theme-banner/ThemeBannerThree'






const SoftwareDevelopment = () => {




    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }

    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper"  >

                {/* Helmet start */}
                <Helmet >
                    <title>Cruxestack</title>
                </Helmet>
                {/* Helmet end */}

                {/*ThemeMenuOne start*/}
                <ThemeMenuOne />
                {/*ThemeMenuOne end*/}

                {/*ThemeBannerOne start*/}
                <ThemeBannerThree />
                {/*ThemeBannerOne end*/}
                
                {/*marque start*/}
                {/* <MarqueSlider /> */}
                {/*marque end*/}

                {/*techy-feature start*/}
                <section className="techy-feature pt-150 pb-30 pt-lg-120">
                    <div className="container">
                        <FeatureThree />

                    </div>
                </section>
                {/*techy-feature end*/}

                {/*about-cruxestack start*/}
                <section className="about-techy bg-shape-3c pt-40 pb-105 pt-lg-60 pb-lg-10">
          <img
            className="about-shape-3c shape-1b d-none d-xl-inline-block"
            src="assets/img/shape/star-3b.svg"
            alt="shape" />
          <img
            className="about-shape-3c shape-2b d-none d-md-inline-block"
            src="assets/img/shape/star-4b.svg"
            alt="shape" />
          <img
            className="about-shape-3c shape-3b d-none d-lg-inline-block"
            src="assets/img/shape/star-4b.svg"
            alt="shape" />
          <img
            className="about-shape-3c shape-4b"
            src="assets/img/shape/star-7c.svg"
            alt="shape" />
          <img
            className="about-shape-3c shape-5b d-none d-xl-inline-block"
            src="assets/img/shape/star-4b.svg "
            alt="shape" />
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center">
              <div className="col-lg-6" data-aos="fade-right" data-aos-delay={100}>
                <div
                  data-tilt
                  className="about-img-wrapper-three text-lg-end position-relative mb-45">
                  <Tilt>
                    <img className="main-img" src="assets/img/hero/banner2.jpg" alt="cruxestack" />
                  </Tilt>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left" data-aos-delay={100}>
                <BlockStyleSeven />
              </div>
            </div>
          </div>
        </section>
                {/*about-techy end*/}

                {/*our-services start*/}
                <section className="our-services service-bg-two pt-95 pb-50 pt-lg-50 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Our Services</h3>
                                    <p data-aos="fade-up" data-aos-delay={100}>Do you want your brand to stand out in the digital age ?</p>
                                </div>
                            </div>
                        </div>
                        {/*feature-style-four*/}
                        <FeatureFour />
                        {/*feature-style-four*/}
                    </div>
                </section>
                {/*our-services end*/}

                {/*latest-case-work start*/}
                <section className="latest-case-work slick-nav blog-slick pt-145 pb-150 pt-lg-60 pb-lg-120">
                    <img className="case-shape shape-1b d-none d-lg-inline-block" src="assets/img/shape/star-3b.svg" alt="shape" />
                    <img className="case-shape shape-2b d-none d-lg-inline-block" src="assets/img/shape/star-4b.svg" alt="shape" />
                    <img className="case-shape shape-3b d-none d-lg-inline-block" src="assets/img/shape/star-2.svg" alt="shape" />
                    <img className="case-shape shape-4b d-none d-lg-inline-block" src="assets/img/shape/star-5b.svg" alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title mb-55 text-md-start text-center">
                                    <h6 className="sub-title mb-20" data-aos="fade-up">Latest Works</h6>
                                    <h3 className="sect-title mb-25" data-aos="fade-up" data-aos-delay={100}>Check out some
                                        great case studies</h3>
                                </div>
                            </div>
                        </div>
                        {/* CaseSliderOne */}
                        <CaseSliderOne />
                        {/* CaseSliderOne */}
                    </div>
                </section>
                {/*latest-case-work end*/}


                {/*techy-wrok-process start*/}
                <section className="techy-wrok-process pt-140 pb-30 pt-lg-55">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb-55">
                                    <h6 className="sub-title mb-20">Working Process</h6>
                                    <h2 className="sect-title">How It Works</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xl-7 col-lg-6">
                                <div className="process-img-wrapper mb-45">
                                    <img src="assets/img/work/process-1b.jpg" alt="img" />
                                    <div className="call-box">
                                        <div className="icon">
                                            <img src="assets/img/icon/icon-27b.svg" alt="icon" />
                                        </div>
                                        <h3 className="sect-title-two">Do you have
                                            any question?</h3>
                                        <h4 className="fs-24 text-uppercase text-theme fw-medium">+90 565 74 44</h4>
                                        <h4 className="fs-24 text-uppercase text-theme fw-medium">+49 152 52387362</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                {/* BlockStylFive */}
                                <BlockStylFive />
                                {/* BlockStylFive */}
                            </div>
                        </div>
                    </div>
                </section>
                {/*techy-wrok-process end*/}

                {/* FooterTwo */}
                <FooterTwo />
                {/* FooterTwo */}
                <CookieConsent
                    debug={false}
                    location="bottom"
                    style={{
                        background: "#913bff",
                        fontSize: "90%",
                        alignItems: "center",
                        zIndex: 10002,
                        border: '1px solid',
                        borderRadius: '20px'
                    }}
                    buttonStyle={{
                        color: "#913bff",
                        backgroundColor: 'white',
                        borderRadius: '15px',
                        border: '1px solid',
                    }}
                    buttonText="Accept"
                    expires={150}
                >
                    We use cookies to provide necessary site functionality, to provide the best possible user experience,
                    and to tailor future communications.<br /> By using this website you accept the use of cookies.
                    &nbsp;You can access our&nbsp;<Link style={{ color: '#00FFFF' }} to="/privacy"><u> Privacy Policy</u>
                    </Link>&nbsp;here
                </CookieConsent>
            </div>
        </Fragment>
    )
}

export default SoftwareDevelopment