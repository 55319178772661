import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const procesContent =[
    {
       icon: 'icon-24b',
       title: 'Software Prototyping',
       desc: 'Based on the information received from you, the application content view and flowchart are created and submitted for your approval',
       margin: 'mb-55',
    },
    {
       icon: 'icon-25b',
       title: 'Test Process',
       desc: 'The created software is presented to you as a preview and after you test it for a certain period of time, the application development process is completed.',
       margin: 'mb-55'
    },
    {
       icon: 'icon-26b',
       title: 'Service Process',
       desc: 'Delivered or published software continues to be developed in line with your requests',
       margin: 'mb-55'
    }
]

const BlockStylFive = () => {
    return (
        <Fragment>
            <div className="block-style-six">
                {procesContent.map((val,i)=>(
                    <div key={i} className={`process-box ${val.margin}`}>
                        <div className="img">
                            <img  src={`assets/img/icon/${val.icon}.svg`} alt="icon"/>
                        </div>
                        <div className="text-wrapper">
                            <h3 className="sect-title-two">{val.title}</h3>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                ))}
                <div className="text-md-start text-center">
                    <Link className="theme_btn" to="/services">More Details</Link>
                </div>
            </div>
        </Fragment>
    )
}

export default BlockStylFive