import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'






//componets
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import FooterTwo from '../../../components/footer/FooterTwo'
import ContactForm from '../../../components/form/ContactForm'
import SocialThree from '../../../components/social/SocialThree'





const Contact = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
            {/* <div style={{backgroundColor:'#dde0e0 ', overflowX:'hidden'}}> */}
            {/* <div style={{ backgroundImage: 'url(' + BG + ')', backgroundSize: 'auto', overflowX:'hidden' }} > */}
                {/* Helmet start */}
                <Helmet>
                    <title>Contact || Cruxestack</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuOne */}
                <ThemeMenuOne />
                {/* ThemeMenuOne */}

                {/* PageTitle */}
                <PageTitle title="Contact" />
                {/* PageTitle */}

                {/*contact-section start*/}
                <div className="contact-section pt-145 pb-100 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-5">
                                <div className="contact-info-wrapper me-xxl-5 pe-xxl-5 mb-45">
                                    <h2 className="sect-title mb-5">Let’s Get Connect With Us</h2>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-telephone" />
                                        </div>
                                        <span><a href="tel:{[+90553565744]}">  +90 553 565 74 44</a></span>
                                        <span className="mb-10"><a href="https://wa.me/+4915252387362" target='_blank'> +49 152 52387362</a></span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-geo-alt" />
                                        </div>
                                        <span className="mb-10">Bursa Türkiye</span>
                                        <span>Hannover Germany </span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-envelope" />
                                        </div>
                                        <span className="mb-10"><a href="mailto:info@cruxestack.com">info@cruxestack.com</a></span>
                                        <span className="mb-10"><a href="mailto:y.derekoylu@cruxestack.com">y.derekoylu@cruxestack.com</a></span>
                                    </div>
                                    {/* <div className="single-info-box mb-40">
                                        <div className="icon">
                                            <i className="bi bi-briefcase" />
                                        </div>
                                        <span className="mb-10">Starbucks</span>
                                        <span>Louis Vuitton</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-content-wrapper mb-45">
                                    <div className="row gx-4 gx-xxl-5 px-0 align-items-center mb-25">
                                        <div className="col-xxl-8 col-md-7">
                                            <div className="text-center text-md-start">
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-md-5">
                                           
                                        </div>
                                    </div>
                                    {/* <div className="contact-map pb-40 mb-xxl-4">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d409.1606901376509!2d9.73186544491037!3d52.375947617182796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b00b514d494f85%3A0x425ac6d94ac4720!2sHannover%2C%20Almanya!5e0!3m2!1str!2str!4v1728829982549!5m2!1str!2str"/>
                                    </div> */}
                                    <div className="contact-form-two">
                                        <h3 className="post-title mb-50">Send Us Message</h3>
                                        {/* ContactForm start */}
                                        <ContactForm />
                                        {/* ContactForm end */}
                                    </div>
                                   
                                </div>
                                 {/* SocialThree start */}
                                    <SocialThree />
                                    {/* SocialThree end */}
                            </div>
                        </div>
                    </div>
                </div>
                {/*contact-section end*/}

                {/* FooterTwo start */}
                <FooterTwo />
                {/* FooterTwo end */}

            </div>

        </Fragment>
    )
}

export default Contact