import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'




//componets
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import ProjectMasonryGrid from '../../../components/project/ProjectMasonryGrid'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import FooterTwo from '../../../components/footer/FooterTwo'
import { Link } from 'react-router-dom'
import BG from '../../../assets/img/bg/bg1.jpg'


const Privacy = () => {

    return (
        <Fragment>
            {/* <div style={{backgroundColor:'#010207', overflowX:'hidden'}}> */}
            {/* <div style={{ backgroundImage: 'url(' + BG + ')', backgroundSize: 'auto', overflowX:'hidden' }} > */}
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                <title>Project || Cruxestack  </title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuOne */}
                <ThemeMenuOne />
                {/* ThemeMenuOne */}

                {/* PageTitle */}
                <PageTitle title="Privacy Policy" />
                {/* PageTitle */}

                {/*feature-work start*/}
                <section className="techy-project-masonry pt-145 pb-105 pt-lg-55 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-6 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-25">
                                    <h6 className="sub-title mb-20">Cruxestack</h6>
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Privacy Policy</h3>
                                </div>
                            </div>
                        </div>
                       <div>
                        <p style={{fontSize:'18px'}}>
                         Cruxestack Dijital produces and publishes web applications, mobile applications and recreational 
                        social games for web and mobile devices.  
                        Hereinafter Cruxestack Dijital” or “We” in this Privacy Policy 
                        refers to Cruxestack Dijital Ltd. Şti., its subsidiaries, parent companies, joint 
                        ventures and other corporate entities under common ownership and/or their 
                        representatives, agents, consultants, employees, directors and managers and 
                        all ventures connected with  Cruxestack Dijital Ltd. Şti.
                        Hereinafter “ Services ”; refers to all services related to applications or 
                        games, entertainment pages and forums, applications and / or games made available through 
                        application stores, all websites open to access, entertainment pages and forums, 
                        applications and / or games owned by Cruxestack Dijital and which Cruxestack Dijital has the legal and / or technical right to influence.
                        Hereinafter “You, Yours, Users” refers to all persons who make use of Cruxestack Digital's Services.
                        <br/>
                         Cruxestack Digital respects the privacy rights of Users who utilize the Services. In this Privacy Policy; 
                        We specify what information about Users, how and why 
                        we collect, how we use this information and with whom we share it, how we access this information 
                        and how we update the information, how the information is collected, used and protected.
                        <br/>
                        When you access the Services, visit the Services or download / install / install / install, use, 
                        benefit from the Services, you accept this Privacy Policy and Terms of Service and you expressly 
                        accept and declare that there is no harm in using / processing / storing all information and 
                        documents obtained through the Services within the scope of this document.
                        We state that Cruxestack Dijital is not responsible for the actions of third parties, the content 
                        of websites, the information you provide to such third parties or the use of services provided 
                        by third parties.
                        <br />
                        Cruxestack Dijital reserves the right to update / revise this Privacy Policy within its own structure. 
                        As long as you continue to benefit from the Services, you are deemed to have accepted the most 
                        current version of this Privacy Policy in case of updates / revisions in this Privacy Policy.
                        If you do not accept this Privacy Policy and/or Terms of Use Agreement, we strongly 
                        inform you that you should not benefit from the Services.</p>
                       </div>
                    </div>
                </section>
                {/*feature-work end*/}

                {/*techy-newsletter start*/}
                <NewsletterTwo />
                {/*techy-newsletter end*/}

                {/* FooterTwo start */}
                <FooterTwo />
                {/* FooterTwo end */}

            </div>

        </Fragment>
    )
}

export default Privacy