import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ScrollTopBehavior from '../components/ScrollTopBehavior'

//Home Pages
import SoftwareDevelopment from '../views/home-pages/SoftwareDevelopment'

//inner-pages
//about-page
import About from '../views/inner-pages/about/About'

//services-pages
import Services from '../views/inner-pages/services/Services'

//team-pages
import Team from '../views/inner-pages/team/Team'
// import TeamDetails from '../views/inner-pages/team/TeamDetails'

//pages
// import Price from '../views/inner-pages/pages/Price'
// import Faq from '../views/inner-pages/pages/Faq'
import Error from '../views/inner-pages/pages/Error'
import ComingSoon from '../views/inner-pages/pages/ComingSoon'

//project-pages
import Projects from '../views/inner-pages/project/Projects'


//conatct-page
import Contact from '../views/inner-pages/pages/Contact'

//error-404
import NotFound from '../views/NotFound'

//privacy-Policy
import Privacy from '../views/inner-pages/pages/Privacy'


const AppRouter = () => {
    return (
        <>
            <ScrollTopBehavior />
            <Routes>
                <Route path="/" element={<SoftwareDevelopment />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/team" element={<Team />} />
                {/* <Route path="/team-details" element={<TeamDetails />} /> */}
                {/* <Route path="/price" element={<Price />} />
                <Route path="/faq" element={<Faq />} /> */}
                <Route path="/error" element={<Error />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path="*" element={<NotFound />} />
              
            </Routes>
        </>
    )
}

export default AppRouter 

