import React, {Fragment} from 'react'
import { Link } from 'react-router-dom';
import { SubscribeFormOne } from '../form/SubscribeFormOne';
import CopyRightThree from './CopyRightThree';
import BG from '../../assets/img/qrCode/qr-code.png'


const pageContent =[
    {
        name: 'About Us',
        routerPath: '/about'
    },
    {
        name: 'Services',
        routerPath: '/services'
    },
    {
        name: 'Projects',
        routerPath: '/projects'
    },
    {
        name: 'Blog',
        routerPath: '/coming-soon'
    },
    {
        name: 'Contact',
        routerPath: '/contact'
    },
]

const serviceContent =[
    {
        mailName: 'info@cruxestack.com',
        mail: 'mailto:info@cruxestack.com',
    },
    {
        name:'Bursa - Türkiye',
        tel: '+90 553 565 74 44',
        routerPath: 'tel:+905535657444'
    },
    {
        name:'Hannover - Germany',
        tel: '+44 1525-2387362',
        routerPath: 'https://wa.me/+4915252387362'
    },

];

const socialContent =[
    {
        icon: 'fab fa-facebook-f',
        routerPath: 'https://www.facebook.com/profile.php?id=61565189462248'
    },
    {
        icon: 'fab fa-x',
        routerPath: 'https://x.com/cruxestack'
    },
    {
        icon: 'fab fa-linkedin-in',
        routerPath: 'https://www.linkedin.com/company/cruxestack'
    },
    {
        icon: 'fab fa-instagram',
        routerPath: 'https://www.instagram.com/cruxestack'
    },

]



const FooterTwo = () => {
    return (
        <Fragment>
            {/*footer-area start*/}
            <footer
                className="gradient-bg footer-area theme-footer-two pt-95 pb-80 pb-lg-10">
                <img className="footer-shape shape-1b" src="assets/img/shape/star-3b.svg" alt="shape"/>
                <img
                    className="footer-shape shape-2b d-none d-lg-inline-block"
                    src="assets/img/shape/star-4b.svg"
                    alt="shape"/>
                <img className="footer-shape shape-3b" src="assets/img/shape/star-6b.svg" alt="shape"/>
                <img
                    className="footer-shape shape-4b d-none d-lg-inline-block"
                    src="assets/img/shape/star-5b.svg"
                    alt="shape"/>
                <img className="footer-shape shape-5b" src="assets/img/shape/star-5b.svg" alt="shape"/>
                <img
                    className="fot-shape-one"
                    src="assets/img/shape/line-round-7b.svg"
                    alt="footer shape"/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 mb-10">
                        <div className="col-xl-2 col-md-6" data-aos="fade-up">
                            <div className="footer__widget mb-10">
                            <div
                                className="footer__widget mb-30"
                                data-aos="fade-right"
                                data-aos-delay={200}>
                                <p className="pe-xl-3 foter-para">Get’s IT Solutions For Expert Consultants
                                </p>
                                <Link className="foter-btn" to="/contact">Let’s Chat
                                    <i className="bi bi-arrow-up-right"/>
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6" data-aos="fade-up" data-aos-delay={100}>
                            <div className="footer__widget mb-40 ps-xxl-4">
                            <h4 className="widget-title mb-35">Quick Links</h4>
                                <ul className="fot-list">
                                   {pageContent.map((val, i)=>(
                                        <li key={i}>
                                            <Link to={val.routerPath}>{val.name}</Link>
                                        </li>
                                   ))}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xl-4 col-md-6 ps-xxl-3"
                            data-aos="fade-up"
                            data-aos-delay={200}>
                            <div className="footer__widget mb-40 px-xxl-5">
                            <h4 className="widget-title mb-35">Contact</h4>
                                <ul className="fot-list">
                                    {serviceContent.map((val, i)=>(
                                        <li  key={i}>
                                            <li >
                                            <a href={val.mail}>{val.mailName}</a>
                                            </li>
                                           
                                            <li className='mt--25 fot-title'>{val.name}</li>
                                            <a href={val.routerPath}> {val.tel} </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xl-4 col-md-6 ps-xxl-3"
                            data-aos="fade-up"
                            data-aos-delay={200}>
                            <div className="footer__widget mb-40 px-xxl-5">
                                <h4 className="widget-title mb-35">Subscribe</h4>
                                <p className="fs-17 mb-0 pb-20">
                                Stay in the know with our newsletter...
                                </p>
                                {/* SubscribeFormOne */}
                               <SubscribeFormOne />
                               <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                            <div className="footer__widget mb-40" style={{top:'40px'}}>
                                    <img src='../../assets/img/qrCode/qr-code.png' 
                                       style={{width:'120px'}} 
                                    />
                            </div>
                        </div>
                                {/* SubscribeFormOne */}
                             
                                {/* <div className="footer__social mb-30">
                                    {socialContent.map((val, i)=>(
                                        <a key={i} href={val.routerPath}>
                                            <i className={val.icon}/>
                                        </a>
                                    ))}
                                </div> */}
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="container">
                    <CopyRightThree />
                </div>
            </footer>
            {/*footer-area end*/}

        </Fragment>
    )
}

export default FooterTwo