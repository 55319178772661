import React, {Fragment} from 'react'
import Slider from 'react-slick';

const caseContent = [
    {
        img: 'hdApp',
        tag: 'App Development',
        title: 'Herkes Duysun',
        desc: "Türkiye's new media, Türkiye's World's national internet news site, herkesduysun.com, has started its broadcasting life.",
        icon: 'bi bi-arrow-up-right',
    },
    {
        img: 'beksiadd',
        tag: 'Web Development',
        title: 'Beksiad',
        desc: "Baby Child's Confection Sector Industry And Businessmen Association",
        icon: 'bi bi-arrow-up-right',
    },
    {
        img: 'charge',
        tag: 'App Development',
        title: 'Ev Charge',
        desc: "Charge your vehicle safely and with performance at home with Makro Charge electric vehicle chargers.",
        icon: 'bi bi-arrow-up-right',
    },
    {
        img: 'LoolLogoo',
        tag: 'App Development',
        title: 'Lool',
        desc: "Join LOOL, a social app community of car and motorsports fans who connect through their shared passion of cars, groups and experiences.",
        icon: 'bi bi-arrow-up-right',
    },
    
];

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
         {
            breakpoint: 991,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                arrows:false,
            }
        }
    ]
};


const CaseSliderOne = () => {
    return (
        <Fragment>
            <Slider {...settings} className="row gx-0 px-0 case-slider-one" data-aos="fade-up" data-aos-delay={200}>
                {caseContent.map((val, i)=>(
                    <div key={i} className="col-lg-4">
                        <div className="case-item-one">
                            <div className="case-thumb">
                                <img className="w-100"  src={`assets/img/work/${val.img}.png`} alt="img"/>
                            </div>
                            <div className="case-content">
                                {/* <span>{val.tag}</span> */}
                                {/* <h3>
                                    <Link className="sect-title-two" to="/">{val.title}</Link>
                                </h3> */}
                                <p>{val.desc}</p>
                                {/* <Link className="case-btn" to="/project-details"><i className={val.icon}/></Link> */}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </Fragment>
    )
}

export default CaseSliderOne