import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const BlockStyleOneA = () => {
    return (
        <Fragment>
            <div className="block-style-one ps-lg-1 mb-45">
                <h2 className="mb-15">Sharpen Your Focus On Cruxes</h2>
                <p>Cruxes is a software company dedicated to developing solutions that go
                    beyond the surface.We believe that true progress lies in
                    tackling the fundamental challenges (cruxes) that hinder productivity and success..</p>
                {/* <ul className="block-box box-two list-none">
                    <li>
                        <a href="contact.html">
                            <span className="img-bg"><img src="assets/img/icon/icon-1a.svg" alt="icon"/></span>
                            <span>Money Back Guarantee</span>
                        </a>
                    </li>
                    <li>
                        <a href="contact.html">
                            <span className="img-bg"><img src="assets/img/icon/icon-2a.svg" alt="icon"/></span>
                            <span>24/7 Online Support</span>
                        </a>
                    </li>
                </ul> */}
                {/* <Link className="theme_btn mt-60" to="/about">More Details</Link> */}
            </div>
        </Fragment>
    )
}

export default BlockStyleOneA