import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//components
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import FeatureFour from '../../../components/feature/FeatureFour'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import FooterTwo from '../../../components/footer/FooterTwo'
import BG from '../../../assets/img/bg/bg1.jpg'


const Services = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
            {/* <div style={{backgroundColor:'#010207', overflowX:'hidden'}}>
            <div style={{ backgroundImage: 'url(' + BG + ')', backgroundSize: 'auto', overflowX:'hidden' }} > */}

                {/* Helmet start */}
                <Helmet>
                    <title>Services || Cruxestack</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuOne */}
                <ThemeMenuOne />
                {/* ThemeMenuOne */}

                {/* PageTitle */}
                <PageTitle title="Services" />
                {/* PageTitle */}

                {/*our-services start*/}
                <section className="our-services service-bg-two pt-145 pb-100 pt-lg-60 pb-lg-20">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Our Services</h3>
                                    <p data-aos="fade-up" data-aos-delay={100}>Sharpen Your Focus On Cruxes</p>
                                </div>
                            </div>
                        </div>
                        <FeatureFour />
                    </div>
                </section>
                {/*our-services end*/}


                {/*techy-newsletter start*/}
                <NewsletterTwo />
                {/*techy-newsletter end*/}

                {/* FooterTwo start */}
                <FooterTwo />
                {/* FooterTwo end */}

            </div>

        </Fragment>
    )
}

export default Services